
/* eslint-disable */
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterView',
  // 必须在setup里执行并return
  setup(){
  
   
    return {}
  }
});

