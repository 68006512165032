
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'CustomerView',
  // 必须在setup里执行并return
  setup(){


    return {}
  }
});

