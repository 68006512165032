
  import { defineComponent, onMounted } from "vue";
  import { useRouter, useRoute } from "vue-router";
  import HeaderView from "@/components/HeaderView.vue";
  import FooterView from "@/components/FooterView.vue";
  import CustomerView from "@/components/CustomerView.vue";
  // 引入swiper
  import Swiper, {
    Autoplay,
    EffectCoverflow,
    EffectCube,
    Pagination,
    Navigation,
  } from "swiper";
  Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);

  // swiper-bundle.min.css 决定了小圆点和左右翻页标签，如果不需要可以不引用
  import "swiper/swiper-bundle.min.css";

  // swiper.less/sass/css 决定了基础的样式
  import "swiper/swiper.min.css";

  export default defineComponent({
    name: "HomeView",
    components: {
      HeaderView,
      FooterView,
      CustomerView,
    },
    setup() {
      onMounted(() => {
        new Swiper(".swiper1", {
          //循环
          loop: true,
          //每张播放时长3秒，自动播放
          spaceBetween: 25,
          pagination: {
            el: ".swiper-pagination",
          },
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
        });

        new Swiper(".swiper2", {
          //循环
          loop: true,
          //每张播放时长3秒，自动播放
          spaceBetween: 25,
          pagination: {
            el: ".swiper-pagination",
          },
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      });
      const router = useRouter(),
        route = useRoute();
      function btn() {
        router.push({ name: "about", params: { currentTab: "second" } });
      }
      function btn1() {
        router.push({ name: "about", params: { currentTab: "third" } });
      }
      function btn2() {
        router.push({ name: "about", params: { currentTab: "fourth" } });
      }
      function btn3() {
        router.push({ name: "production", params: { currentTab: "second" } });
      }
      function btn4() {
        router.push({ name: "production", params: { currentTab: "third" } });
      }
      return { btn, btn1, btn2, btn3, btn4 };
    },
  });
