
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'HeaderView',
  // 必须在setup里执行并return
  setup(){
  
   
    return {}
  }
});

